<template>
  <div id="app">
    <Navbar></Navbar>
    <router-view></router-view>
    <Footer></Footer>
  </div>
</template>

<script>
import Navbar from "./components/layout/Navbar";
import Footer from "./components/layout/Footer";

export default {
  components: { Navbar, Footer },
  data() {
    return {
      showMenu: false
    };
  } 
};
</script>

<style lang="scss">
// Import Bulma's core
@import "~bulma/sass/utilities/_all";
@import "./assets/css/animate.css";

#app {
  position: relative;
  padding-bottom: 14rem;
  min-height: calc(100vh);

  @include widescreen() {
    padding-bottom: 17rem;
  }

  @include tablet() {
    padding-bottom: 20rem;
  }

  @include mobile() {
    padding-bottom: 30rem;
  }
}

// Footer
$footer-background-color: $link;
$footer-padding: 3rem 1.5rem 3rem;

// Set your colors
$twitter: #4099FF;
$twitter-invert: findColorInvert($twitter);

// Setup $colors to use as bulma classes (e.g. 'is-twitter')
$colors: (
    "white": ($white, $black),
    "black": ($black, $white),
    "light": ($light, $light-invert),
    "dark": ($dark, $dark-invert),
    "primary": ($primary, $primary-invert),
    "info": ($info, $info-invert),
    "success": ($success, $success-invert),
    "warning": ($warning, $warning-invert),
    "danger": ($danger, $danger-invert),
    "twitter": ($twitter, $twitter-invert),
    "link": ($blue, findColorInvert($blue))
);

// Import Bulma and Buefy styles
@import "~bulma";
@import "~buefy/src/scss/buefy";

// Util
$sizeUnit: rem;
$marginKey: "m";
$paddingKey: "p";
$separator: "-";
$sizes: (
  ("none", 0),
  ("xxs", 0.125),
  ("xs", 0.25),
  ("sm", 0.5),
  ("md", 1),
  ("lg", 2),
  ("xl", 4),
  ("xxl", 8)
);
$positions: (("t", "top"), ("r", "right"), ("b", "bottom"), ("l", "left"));

@function sizeValue($key, $value) {
  @return if($key == "none", 0, $value + $sizeUnit);
}

@each $size in $sizes {
  $sizeKey: nth($size, 1);
  $sizeValue: nth($size, 2);
  .#{$marginKey}#{$separator}#{$sizeKey} {
    margin: sizeValue($sizeKey, $sizeValue);
  }
  .#{$paddingKey}#{$separator}#{$sizeKey} {
    padding: sizeValue($sizeKey, $sizeValue);
  }
  @each $position in $positions {
    $posKey: nth($position, 1);
    $posValue: nth($position, 2);
    .#{$marginKey}#{$separator}#{$posKey}#{$separator}#{$sizeKey} {
      margin-#{$posValue}: sizeValue($sizeKey, $sizeValue);
    }
    .#{$paddingKey}#{$separator}#{$posKey}#{$separator}#{$sizeKey} {
      padding-#{$posValue}: sizeValue($sizeKey, $sizeValue);
    }
  }
}
.pagination-custom {
  display: flex;
  justify-content: flex-end;
}
.multiselect__content-wrapper {
  z-index: 999;
  position: relative;
}
</style>
