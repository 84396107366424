import Axios from 'axios';
import jwtDecode from 'jwt-decode';
import { differenceInSeconds } from 'date-fns';

const tokenKey = process.env.VUE_APP_TOKEN_KEY;

/**
 * Check if player is Auth
 * @returns {void}
 */
export function isAuth() {
  let currentTime = Date.now() / 1000;
  let profileUser = localStorage.getItem('profile');
  let token = localStorage.getItem(tokenKey)
  ? localStorage.getItem(tokenKey)
  : null;
  if (!token) return false;
  // Axios.defaults.headers.common['Authorization'] = `Bearer ${token.access_token}`;
  token = jwtDecode(token);

  if (token.exp > currentTime) {
    const diff = differenceInSeconds(
      new Date(token.exp * 1000),
      new Date(currentTime * 1000)
    );
    if (diff < 320) {
      Axios.get('/v1/auth/refresh-token')
        .then(data => {
          localStorage.setItem(tokenKey, JSON.stringify(data.data));
          const json = localStorage.getItem(tokenKey)
            ? JSON.parse(localStorage.getItem(tokenKey))
            : null;
          const token = json && json.access_token ? json.access_token : null;
          if (token)
            Axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        })
        .catch(err => {
          console.error(err);
        });
    }
    return token, profileUser;
  }
  return false;
}

/**
 * Check if player has profile
 * @param {string} profile
 */
export const hasProfile = profile => {
  const profileUser = isAuth();
  if (profileUser && profileUser.includes(profile)) {
    return true;
  }
  return false;
};
