import Vue from "vue";
import Router from "vue-router";
import { isAuth } from "./global/auth";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "Assban",
      component: () => import("./components/pages/default/Home.vue")
    },
    {
      path: "/login",
      name: "Login",
      component: () => import("./components/pages/default/Login.vue")
    },
    {
      path: "/cursos",
      name: "Cursos",
      component: () => import("./components/pages/default/Cursos.vue")
    },
    // {
    //   path: "/record",
    //   name: "Record",
    //   component: () => import("./components/pages/default/Record.vue")
    // },
    {
      path: "/detalhe-cursos/:name",
      name: "Detalhes do curso",
      component: () => import("./components/pages/default/DetailCursos.vue")
    },
    {
      path: "/duvidas",
      name: "Dúvidas",
      component: () => import("./components/pages/default/Duvidas.vue")
    },
    {
      path: "/fale-conosco",
      name: "Fale conosco",
      component: () => import("./components/pages/default/ContactUs.vue")
    },
    {
      path: "/register",
      name: "Resgistrar",
      component: () => import("./components/pages/default/Register.vue")
    },
    {
      path: "/Recuperar senha/:name",
      name: "Recuperar senha",
      component: () => import("./components/pages/default/RecoveryPassword.vue")
    },
    {
      path: "/user/profile",
      name: "Perfil",
      component: () => import("./components/pages/user/Profile.vue"),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/user/enroll",
      name: "Minhas Inscrições",
      component: () => import("./components/pages/user/Inscricoes"),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/user/cursos",
      name: "Meus Cursos",
      component: () => import("./components/pages/user/Cursos.vue"),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/user/curso-usuario/:name",
      name: "userCourseUser",
      component: () => import("./components/pages/user/CourseUser.vue"),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/user/exercicio/:name/:cur/:mod",
      name: "Prova",
      component: () => import("./components/pages/user/Exercice.vue"),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/user/video/:name/:cur/:mod/:status/:skip",
      name: "Vídeo",
      component: () => import("./components/pages/user/Video.vue"),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/admin/users",
      name: "Usuários",
      component: () => import("./components/pages/admin/User.vue"),
      meta: {
        requiresAuth: true,
        is_manager: true
      }
    },
    {
      path: "/admin/dashboard",
      name: "Dashboard",
      component: () => import("./components/pages/admin/Dashboard.vue"),
      meta: {
        requiresAuth: true,
        is_manager: true
      }
    },
    {
      path: "/admin/cursos",
      name: "Cursos admin",
      component: () => import("./components/pages/admin/Cursos.vue"),
      meta: {
        requiresAuth: true,
        is_admin: true
      }
    },
    {
      path: "/admin/grupos",
      name: "Grupos",
      component: () => import("./components/pages/admin/Grupos.vue"),
      meta: {
        requiresAuth: true,
        is_admin: true
      }
    },
    {
      path: "/admin/fale-conosco",
      name: "Fale conosco Admin",
      component: () => import("./components/pages/admin/ContactUs.vue"),
      meta: {
        requiresAuth: true,
        is_admin: true
      }
    },
    {
      path: "/admin/payments",
      name: "Pagamentos",
      component: () => import("./components/pages/admin/Payments.vue"),
      meta: {
        requiresAuth: true,
        is_manager: true
      }
    },
    {
      path: "/admin/exame",
      name: "Exame",
      component: () => import("./components/pages/admin/Exame.vue"),
      meta: {
        requiresAuth: true,
        is_manager: true
      }
    },
    {
      path: "/admin/import",
      name: "Importação",
      component: () => import("./components/pages/admin/Import.vue"),
      meta: {
        requiresAuth: true,
        is_admin: true
      }
    },
    {
      path: "/admin/percent",
      name: "Percentual",
      component: () => import("./components/pages/admin/Percent.vue"),
      meta: {
        requiresAuth: true,
        is_manager: true
      }
    },
    {
      path: "/admin/instrutor",
      name: "Instrutor",
      component: () => import("./components/pages/admin/Instrutor.vue"),
      meta: {
        requiresAuth: true,
        is_admin: true
      }
    },
    {
      path: "/admin/inscricao",
      name: "Inscrição",
      component: () => import("./components/pages/admin/Inscricao.vue"),
      meta: {
        requiresAuth: true,
        is_admin: true
      }
    },
    {
      path: "/admin/modulos/:name",
      name: "Módulos",
      component: () => import("./components/pages/admin/Modulos.vue"),
      meta: {
        requiresAuth: true,
        is_admin: true
      }
    },
    {
      path: "/admin/licoes/:name/:cur",
      name: "Lição",
      component: () => import("./components/pages/admin/Licoes.vue"),
      meta: {
        requiresAuth: true,
        is_admin: true
      }
    },
    {
      path: "/admin/question/:name/:mod/:cur",
      name: "Questão",
      component: () => import("./components/pages/admin/Question.vue"),
      meta: {
        requiresAuth: true,
        is_admin: true
      }
    }
  ]
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isAuth()) {
      next({
        path: "/login",
        params: { nextUrl: to.fullPath }
      });
    } else if (to.matched.some(record => record.meta.is_admin)) {
      if (isAuth() === "admin") {
        next();
      } else {
        next({ path: "/" });
      }
    } else if (to.matched.some(record => record.meta.is_manager)) {
      if (isAuth() === "manager" || isAuth() === "admin") {
        next();
      } else {
        next({ path: "/" });
      }
    } else {
      next();
    }
  } else {
    next();
    if (
      (isAuth() && (to.path === "/login" || to.path === "/register")) ||
      !to.matched.some(record => record.meta)
    ) {
      next({ path: "/" });
    }
  }
});

export default router;
