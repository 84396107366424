<template>
  <footer class="footer has-text-white">
    <div class="container">
      <div class="columns">
        <div class="column">
          <strong class="has-text-white">ASSBAN CURSO ONLINE</strong>
          <p>
            SHCRS Quadra 503, Bloco A, Nº 13
            <br>Brasília-DF, CEP:70331510
            <br>E-mail: <b>cursosonline@assban.com.br</b>
            <br>Telefone/FAX: (61) 3224.1883 / 3224.4445
          </p>
        </div>
        <div class="column">
          <strong class="has-text-white">Formas de Pagamento</strong>
          <div class="pagamentos">
            <img
              src="@/assets/img/cartoesP1.png"
              alt="Formas de pagamentos"
              class="m-r-xxs"
            >
            <img
              src="@/assets/img/cartoesP2.png"
              alt="Formas de pagamentos"
            >
          </div>
          <p>
            Termo de Uso
            <br>Copyright {{ new Date().getFullYear() }} - Todos os Direitos Reservados, ASSBAN
            <br>v1.0.24
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
footer {
  position: absolute;
  bottom: 0;
  display: block;
  width: 100%;
}
.pagamentos {
  display: flex;
  flex-wrap: wrap;
}
</style>
