<template>
  <nav
    class="navbar is-link has-shadow"
    role="navigation"
    aria-label="main navigation"
  >
    <div class="container">
      <div class="navbar-brand">
        <router-link to="/" class="navbar-item">
          <img class="logo m-r-sm" src="@/assets/img/logo-assban.png" />
          <span>ASSBAN ONLINE</span>
        </router-link>

        <a
          role="button"
          class="navbar-burger burger"
          aria-label="menu"
          aria-expanded="false"
          @click="showMenu = !showMenu"
          :class="{ 'is-active': showMenu }"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div class="navbar-menu" :class="{ 'is-active': showMenu }">
        <div class="navbar-start">
          <router-link
            to="/cursos"
            class="navbar-item"
            @click.native="showMenu = !showMenu"
            v-if="!isAuth()"
            active-class="is-active"
            >CURSOS</router-link
          >

          <router-link
            to="/user/cursos"
            class="navbar-item"
            @click.native="showMenu = !showMenu"
            v-else
            active-class="is-active"
            >MEUS CURSOS</router-link
          >

          <router-link
            to="/duvidas"
            class="navbar-item"
            @click.native="showMenu = !showMenu"
            active-class="is-active"
            >DÚVIDAS</router-link
          >
          <router-link
            to="/fale-conosco"
            class="navbar-item"
            @click.native="showMenu = !showMenu"
            active-class="is-active"
            >FALE CONOSCO</router-link
          >

          <div
            class="navbar-item has-dropdown is-hoverable"
            v-if="hasProfile('manager') || hasProfile('admin')"
          >
            <a class="navbar-link">ADMINISTRAÇÃO</a>

            <div class="navbar-dropdown">
              <router-link
                to="/admin/percent"
                class="navbar-item"
                @click.native="showMenu = !showMenu"
                active-class="is-active"
                >PERCENTUAL</router-link
              >
              <router-link
                to="/admin/users"
                class="navbar-item"
                @click.native="showMenu = !showMenu"
                active-class="is-active"
                >USUÁRIOS</router-link
              >
              <router-link
                to="/admin/exame"
                class="navbar-item"
                @click.native="showMenu = !showMenu"
                active-class="is-active"
                >EXAME</router-link
              >

              <router-link
                to="/admin/payments"
                class="navbar-item"
                @click.native="showMenu = !showMenu"
                active-class="is-active"
                >PAGAMENTOS</router-link
              >

              <div v-if="hasProfile('admin')">
                <router-link
                  to="/admin/dashboard"
                  class="navbar-item"
                  @click.native="showMenu = !showMenu"
                  active-class="is-active"
                  >DASHBOARD</router-link
                >
                <router-link
                  to="/admin/cursos"
                  class="navbar-item"
                  @click.native="showMenu = !showMenu"
                  active-class="is-active"
                  >CURSOS</router-link
                >
                <router-link
                  to="/admin/grupos"
                  class="navbar-item"
                  @click.native="showMenu = !showMenu"
                  active-class="is-active"
                  >GRUPOS</router-link
                >
                <router-link
                  to="/admin/import"
                  class="navbar-item"
                  @click.native="showMenu = !showMenu"
                  active-class="is-active"
                  >IMPORTAÇÃO</router-link
                >
                <router-link
                  to="/admin/fale-conosco"
                  class="navbar-item"
                  @click.native="showMenu = !showMenu"
                  active-class="is-active"
                  >FALE CONOSCO</router-link
                >
                <router-link
                  to="/admin/instrutor"
                  class="navbar-item"
                  @click.native="showMenu = !showMenu"
                  active-class="is-active"
                  >INSTRUTOR</router-link
                >
                <router-link
                  to="/admin/inscricao"
                  class="navbar-item"
                  @click.native="showMenu = !showMenu"
                  active-class="is-active"
                  >INSCRIÇÃO</router-link
                >
              </div>
            </div>
          </div>
        </div>
        <div class="navbar-end">
          <div class="navbar-item has-dropdown is-hoverable" v-if="isAuth()">
            <div class="navbar-link drop-img">
              <img v-if="user.image" :src="user.image" alt="Img perfil" />
              <img v-else src="@/assets/img/perfil-30.png" alt="Img perfil" />
              <a class="name-user">{{ user.name }}</a>
            </div>

            <div class="navbar-dropdown">
              <router-link
                to="/user/cursos"
                class="navbar-item"
                @click.native="showMenu = !showMenu"
                active-class="is-active"
              >
                <b-icon
                  custom-class="m-r-sm"
                  icon="bookmark"
                  size="is-small"
                ></b-icon>
                <span>MEUS CURSOS</span>
              </router-link>

              <router-link
                to="/user/enroll"
                class="navbar-item"
                @click.native="showMenu = !showMenu"
                active-class="is-active"
              >
                <b-icon
                  custom-class="m-r-sm"
                  icon="book"
                  size="is-small"
                ></b-icon>
                <span>MINHAS INSCRIÇÔES</span>
              </router-link>

              <router-link
                to="/user/profile"
                class="navbar-item"
                @click.native="showMenu = !showMenu"
                active-class="is-active"
              >
                <b-icon
                  custom-class="m-r-sm"
                  icon="pen"
                  size="is-small"
                ></b-icon>
                <span>EDITAR PERFIL</span>
              </router-link>

              <a v-if="isAuth()" @click="logout" class="navbar-item">
                <b-icon
                  custom-class="m-r-sm"
                  icon="power-off"
                  size="is-small"
                ></b-icon>
                <span>SAIR</span>
              </a>
            </div>
          </div>

          <router-link
            to="/register"
            class="navbar-item"
            @click.native="showMenu = !showMenu"
            active-class="is-active"
            v-if="!isAuth()"
            >CADASTRAR</router-link
          >
          <router-link
            to="/login"
            class="navbar-item"
            v-if="!isAuth()"
            @click.native="showMenu = !showMenu"
            active-class="is-active"
            >ENTRAR</router-link
          >
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import Axios from 'axios';
import { hasProfile, isAuth } from '@/global/auth';

const tokenKey = process.env.VUE_APP_TOKEN_KEY;

export default {
  data() {
    return {
      showMenu: false,
      hasProfile,
      isAuth
    };
  },
  methods: {
    logout() {
      localStorage.removeItem(tokenKey);
      localStorage.removeItem('profile');
      this.$router.push({ path: '/login' });
    },
    getMe() {
      if (isAuth()) {
        Axios.get('/v1/users/me')
          .then(data => {
            if (data.data) {
              this.$store.state.user = data.data;
            }
          })
          .catch(erro => {
            console.error(erro);
          });
      }
    }
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  mounted() {
    this.getMe();
  },
  watch: {
    // eslint-disable-next-line
    $route(to, from) {
      this.$forceUpdate(isAuth);
      if (to.name !== 'userCourseUser') document.title = to.name;
    }
  }
};
</script>

<style lang="scss" scoped>
.drop-img {
  img {
    border: 1px solid #fff;
    height: 100%;
    width: 30px;
    border-radius: 50%;
    margin-right: 0.5rem;
  }
  a {
    color: inherit;
  }
  .name-user {
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 130px;
    overflow: hidden;
  }
}
</style>
