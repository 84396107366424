import axios from 'axios';
import { isAuth } from '../global/auth';

const tokenKey = process.env.VUE_APP_TOKEN_KEY;
const baseUrl = process.env.VUE_APP_BASE_URL;


const json = localStorage.getItem(tokenKey)
  ? JSON.parse(localStorage.getItem(tokenKey))
  : null;
const token = json && json.access_token ? json.access_token : null;
if (token) axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

axios.defaults.baseURL = baseUrl;
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.common['cache-control'] = 'no-cache';

const success = res => {
  if (isAuth()) {
    return res;
  } else {
    return res;
  }
};

const error = err => {
  if (err.response && err.response.status === 401) {
    window.location = '/login';
  } else {
    return Promise.reject(err);
  }
};

axios.interceptors.response.use(success, error);