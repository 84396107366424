import jwtDecode from "jwt-decode";
import axios from "axios";

const tokenKey = process.env.VUE_APP_TOKEN_KEY;

const json = localStorage.getItem(tokenKey)
  ? JSON.parse(localStorage.getItem(tokenKey))
  : null;
const token = json && json.token ? json.token : null;

export default {
  state: {
    user: token ? jwtDecode(token).user : null
  },
  mutations: {
    setUser(state, data) {
      if (data && data.access_token) {
        let token = jwtDecode(data.access_token);
        token.user = {};
        token.user.token = "Bearer " + data.access_token;
        state.user = token.user;
        if (token) {
          localStorage.setItem(tokenKey, JSON.stringify(data));
          axios.defaults.headers.common.Authorization = token.user.token;
        } else {
          delete axios.defaults.headers.common;
        }
      } else {
        state.user = null;
      }
    }
  }
};
